.JobCard {
  max-height: 250px;
  margin-bottom: 1rem;
  color: inherit;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 2px rgba(32, 32, 32, 0.12),
    0 1px 2px rgba(32, 32, 32, 0.12);
}

.JobCard:hover {
  /* <Card /> that is also <Link /> */
  text-decoration: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16),
    0 1px 4px rgba(0, 0, 0, 0.23);
}
