.HomePage-image {
  /* background-image: url(assets/professional-people.jpg); */
  background: url(assets/urban-building-skyline-illustration/compressed-skyline.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  /* padding-bottom: 1em; */
  height: calc(100vh - 58px);
  width: 100vw;
  display: flex;
  align-items: center;
}
.HomePage-jumbotron {
  background: #69b1f865;
  margin-left: auto;
  margin-right: auto;
  height: 35%;
  min-height: 300px;
  width: 30%;
  min-width: 250px;
  margin-top: -10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HomePage-flex-item {
  vertical-align: center;
}

.HomePage-button {
  margin-top: 1.5rem;
  color: white;
}
.HomePage-username {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}
